import * as React from 'react';
import { Link } from 'react-router-dom';

import {
  ISurfacesFromFolder,
  ISurfacesFromMultipageTemplate,
} from '@aurigma/design-editor-iframe/ObjectModel/ObjectModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  CARD_SELECTION_SEARCH_PLACEHOLDER,
  BUTTON_REMOVE,
} from '../../constants/labels';

import {
  CardSelectionLisItemtProps,
  CardSelectionLisItemtState,
  CardSelectionListProps,
  CardSelectionListState,
} from '../../@types/Common.d';
import { PrintTemplate } from '../../@types/Data.d';

import imagePlaceholder from '../../resources/img/imagePlaceholder.png';
import { getTemplatePreviewURL } from '../../util/api';
import { CUSTOMER_CANVAS_TEMPLATE_PREVIEW } from '../../constants/network';

/**
 * A card item to select a specific menu point
 */
class CardSelectionListItem extends React.Component<
  CardSelectionLisItemtProps,
  CardSelectionLisItemtState
> {
  constructor(props: CardSelectionLisItemtProps) {
    super(props);

    this.state = { imageSrc: imagePlaceholder };

    this.getTemplatePreviewImage = this.getTemplatePreviewImage.bind(this);

    this.onClickRemove = this.onClickRemove.bind(this);
  }

  componentDidMount(): void {
    const { item } = this.props;

    if ((item as PrintTemplate).product) this.getTemplatePreviewImage();
  }

  /**
   * On click listener for remove an item
   *
   * @param event
   */
  onClickRemove(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.stopPropagation();
    event.preventDefault();

    const { item, itemsRemovable, removeItem } = this.props;

    if (itemsRemovable && removeItem) removeItem(item?.id ?? -1);
  }

  /**
   * On key down listener for remove an item
   *
   * @param event
   * @returns
   */
  onPressRemove(event: React.KeyboardEvent<HTMLDivElement>): void {
    event.stopPropagation();
    event.preventDefault();

    if (event.key !== 'Enter') return;

    const { item, itemsRemovable, removeItem } = this.props;

    if (itemsRemovable && removeItem) removeItem(item?.id ?? -1);
  }

  /**
   * Download a preview image for a layout template
   *
   * @returns
   */
  async getTemplatePreviewImage(): Promise<void> {
    const { item } = this.props;
    const { product } = item as PrintTemplate;

    if (!product) return;

    let imageSrc;

    if ((product.surfaces as ISurfacesFromFolder).designFolder)
      imageSrc = await getTemplatePreviewURL(
        (product.surfaces as ISurfacesFromFolder).designFolder as string
      );
    else if ((product.surfaces as ISurfacesFromMultipageTemplate).file)
      imageSrc = CUSTOMER_CANVAS_TEMPLATE_PREVIEW(
        (product.surfaces as ISurfacesFromMultipageTemplate).file as string
      );

    if (!imageSrc) return;

    this.setState({ imageSrc });
  }

  render(): JSX.Element {
    const { imageSrc } = this.state;
    const { item, linkTo, itemsRemovable } = this.props;

    return (
      <div className="card-selection-item-container">
        {itemsRemovable && (
          <div
            role="button"
            tabIndex={-1}
            className="card-selection-item-remove"
            title={BUTTON_REMOVE}
            onClick={this.onClickRemove}
            onKeyPress={this.onPressRemove}
          >
            <FontAwesomeIcon icon={faTrash} className="remove-icon" />
          </div>
        )}
        <Link className="card-selection-item" to={`${linkTo}${item.id}`}>
          <img src={imageSrc} alt={item.name} />
          <div className="subtitle">{item.name}</div>
        </Link>
      </div>
    );
  }
}

/**
 * A list of card items
 */
export default class CardSelectionList extends React.Component<
  CardSelectionListProps,
  CardSelectionListState
> {
  constructor(props: CardSelectionListProps) {
    super(props);

    this.state = {
      filteredItems: [],
      searchString: '',
    };

    this.onChangeSearch = this.onChangeSearch.bind(this);

    this.filterItems = this.filterItems.bind(this);
  }

  componentDidMount(): void {
    this.filterItems();
  }

  componentDidUpdate(prevProps: CardSelectionListProps): void {
    const { items } = this.props;

    if (JSON.stringify(items) !== JSON.stringify(prevProps.items))
      this.filterItems();
  }

  /**
   * On change listener for searching the list
   *
   * @param event
   */
  onChangeSearch(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    event.preventDefault();

    this.setState(
      { searchString: event.currentTarget.value },
      this.filterItems
    );
  }

  /**
   * Filter the contained card items by a filter string
   */
  filterItems(): void {
    const { items } = this.props;
    const { searchString } = this.state;

    let filteredItems;

    if (searchString.length === 0) filteredItems = items;
    else {
      const searchStringRegex = searchString
        .split('')
        .map((s) => `[${s}].*`)
        .join('');
      const regex = new RegExp(`.*${searchStringRegex}`, 'gi');

      filteredItems = items.filter(({ name }) => name.match(regex));
    }

    this.setState({ filteredItems });
  }

  render(): JSX.Element {
    const { linkTo, itemsRemovable, emptyString, removeItem } = this.props;
    const { searchString, filteredItems } = this.state;

    return (
      <div className="card-selection-container">
        <div className="card-selection-search-container">
          <input
            type="search"
            value={searchString}
            onChange={this.onChangeSearch}
            placeholder={CARD_SELECTION_SEARCH_PLACEHOLDER}
            autoComplete="new-password"
          />
        </div>
        <div className="card-selection-list-container">
          <div className="card-selection-list">
            {filteredItems.length > 0 ? (
              filteredItems.map((item) => (
                <CardSelectionListItem
                  key={item.id}
                  item={item}
                  linkTo={linkTo}
                  itemsRemovable={itemsRemovable}
                  removeItem={removeItem}
                />
              ))
            ) : (
              <div className="card-selection-item-container">
                <div className="card-selection-item empty">
                  <div className="subtitle">{emptyString}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
