// A file containing all labels used in the application

export const INVALID_FIELD = 'Dieses Feld muss befüllt werden.';

export const BUTTON_SAVE = 'Speichern';
export const BUTTON_SAVE_AS = 'Speichern als';
export const BUTTON_PREVIEW = 'Vorschau';
export const BUTTON_DOWNLOAD = 'Download';
export const BUTTON_PULISH = 'Freigeben';
export const BUTTON_CLOSE = 'Schließen';
export const BUTTON_ABORT = 'Abbrechen';
export const BUTTON_REMOVE = 'Entfernen';

export const HOME_ITEM_TITLE_LAYOUTS = 'Laden';
export const HOME_ITEM_TITLE_TEMPLATES = 'Neu';

export const TOP_BAR_NAV_HOME = 'Startseite';
export const TOP_BAR_NAV_LAYOUTS = 'Layouts';
export const TOP_BAR_NAV_TEMPLATES = 'Vorlagen';

export const CARD_SELECTION_SEARCH_PLACEHOLDER = 'Durchsuchen';

export const MODAL_SAVE_LAYOUT_TITLE = 'Name des Entwurfs';
export const MODAL_SAVE_LAYOUT_NAME_LABEL = 'Name';
export const MODAL_SAVE_LAYOUT_NAME_PLACEHOLDER = MODAL_SAVE_LAYOUT_NAME_LABEL;
export const MODAL_SAVE_LAYOUT_NAME_INVALID = INVALID_FIELD;

export const MODAL_PREVIEW_TITLE = 'Vorschau';
export const MODAL_PREVIEW_TITLE_PUBLISH = 'Layoutfreigabe';

export const LAYOUT_SELECTION_EMPTY_LIST = 'Keine Layouts vorhanden.';
export const TEMPLATE_SELECTION_EMPTY_LIST = 'Keine Vorlagen vorhanden.';

export const NOT_FOUND_TITLE = '404 - Die gesuchte Seite wurde nicht gefunden';
export const NOT_FOUND_CONTENT =
  'Die von Ihnen gesuchte Seite konnte leider nicht gefunden werden. Vergewissern Sie sich, dass die URL richtig geschrieben wurde oder Sie gegebenenfalls eingeloggt sind.';
export const NOT_FOUND_GO_BACK = 'Zurück';

export const CLIENT_SELECT_PLACEHOLDER = 'Filiale auswählen';
