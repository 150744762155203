import '../styles/LayoutSelection.scss';

import * as React from 'react';

import CardSelectionList from './Common/CardSelectionList';

import { getClientLayouts, deleteClientLayout } from '../util/api';

import {
  REQUEST_IDENTIFIER_DELETE_CLIENT_LAYOUT,
  REQUEST_IDENTIFIER_GET_CLIENT_LAYOUTS,
} from '../constants/network';
import { ROUTER_ROUTE_EDITOR_LAYOUT } from '../constants/constants';

import { LayoutSelectionProps, LayoutSelectionState } from '../@types/App.d';
import { ClientLayout } from '../@types/Data.d';
import { strCmp } from '../util/util';
import { LAYOUT_SELECTION_EMPTY_LIST } from '../constants/labels';

/**
 * A list of the clients already created and editable layouts
 */
export default class LayoutSelection extends React.Component<
  LayoutSelectionProps,
  LayoutSelectionState
> {
  constructor(props: LayoutSelectionProps) {
    super(props);

    this.state = { layouts: [] };

    this.fetchClientLayouts = this.fetchClientLayouts.bind(this);
    this.removeClientLayout = this.removeClientLayout.bind(this);
  }

  componentDidMount(): void {
    this.fetchClientLayouts();
  }

  componentDidUpdate(prevProps: LayoutSelectionProps): void {
    const { client } = this.props;

    if (client?.id !== prevProps.client?.id) this.fetchClientLayouts();
  }

  /**
   * Get the clients layouts
   *
   * @returns
   */
  async fetchClientLayouts(): Promise<void> {
    const { client, showLoading } = this.props;

    if (!client) return;

    showLoading(true, REQUEST_IDENTIFIER_GET_CLIENT_LAYOUTS);

    const layouts = (await getClientLayouts(
      client.uuid,
      'EDIT'
    )) as ClientLayout[];

    if (!Number.isNaN(+layouts)) {
      showLoading(false, REQUEST_IDENTIFIER_GET_CLIENT_LAYOUTS);

      return;
    }

    this.setState(
      {
        layouts: layouts.sort((a: ClientLayout, b: ClientLayout) =>
          strCmp(a.name, b.name)
        ),
      },
      () => {
        showLoading(false, REQUEST_IDENTIFIER_GET_CLIENT_LAYOUTS);
      }
    );
  }

  /**
   * Delete one of the clients layouts
   *
   * @param layoutId
   * @returns
   */
  async removeClientLayout(layoutId: number): Promise<void> {
    const { client, showLoading } = this.props;

    if (!client) return;

    showLoading(true, REQUEST_IDENTIFIER_DELETE_CLIENT_LAYOUT);

    const deleteResult = await deleteClientLayout(client.uuid, layoutId);

    if (!deleteResult) {
      showLoading(false, REQUEST_IDENTIFIER_DELETE_CLIENT_LAYOUT);

      return;
    }

    const { layouts } = this.state;

    const newLayoutouts = layouts.filter((item) => item.id !== layoutId);

    this.setState(
      {
        layouts: newLayoutouts,
      },
      () => showLoading(false, REQUEST_IDENTIFIER_DELETE_CLIENT_LAYOUT)
    );
  }

  render(): JSX.Element {
    const { layouts } = this.state;

    return (
      <div className="layout-selection-container">
        <CardSelectionList
          items={layouts}
          linkTo={ROUTER_ROUTE_EDITOR_LAYOUT}
          emptyString={LAYOUT_SELECTION_EMPTY_LIST}
          itemsRemovable
          removeItem={this.removeClientLayout}
        />
      </div>
    );
  }
}
