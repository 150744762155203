import * as React from 'react';
import { Spinner } from 'react-bootstrap';

import { LoadingOverlayProps } from '../../@types/Common.d';

/**
 * A loading overlay to prevent the user from interaction while
 * long running tasks
 *
 * @param props
 * @returns
 */
const LoadingOverlay: React.FC<LoadingOverlayProps> = (
  props: LoadingOverlayProps
) => {
  const { loadingTitle, loadingSubtitle } = props;

  return (
    <div className="loading-overlay">
      <div className="loading-overlay-container">
        <div className="label title">{loadingTitle}</div>
        <Spinner animation="border" className="custom-spinner" />
        <div className="label subtitle">{loadingSubtitle}</div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
