import * as React from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import {
  MODAL_SAVE_LAYOUT_NAME_INVALID,
  MODAL_SAVE_LAYOUT_NAME_LABEL,
  MODAL_SAVE_LAYOUT_NAME_PLACEHOLDER,
  MODAL_SAVE_LAYOUT_TITLE,
  BUTTON_ABORT,
  BUTTON_SAVE,
} from '../../constants/labels';

import {
  SaveLayoutModalProps,
  SaveLayoutModalState,
} from '../../@types/Modal.d';

/**
 * A modal to input a name for a layout to be saved
 */
export default class SaveLayoutModal extends React.Component<
  SaveLayoutModalProps,
  SaveLayoutModalState
> {
  constructor(props: SaveLayoutModalProps) {
    super(props);

    this.state = {
      layoutName: '',
      saveCopy: false,
    };

    this.onHide = this.onHide.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onClickAbort = this.onClickAbort.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.resetModal = this.resetModal.bind(this);

    this.setSaveCopy = this.setSaveCopy.bind(this);
  }

  /**
   * Hides and resets the modal
   */
  onHide(): void {
    const { showModal } = this.props;

    this.resetModal();

    showModal(false);
  }

  /**
   * On change listener for the name input
   *
   * @param event
   */
  onChangeName(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    event.preventDefault();

    this.setState({ layoutName: event.currentTarget.value });
  }

  /**
   * On click listener for the abort button
   *
   * @param event
   */
  onClickAbort(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    event.preventDefault();

    this.onHide();
  }

  /**
   * Submit the new name and trigger the actual save process
   *
   * @param event
   */
  onSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.stopPropagation();
    event.preventDefault();

    const { saveLayout } = this.props;
    const { layoutName, saveCopy } = this.state;

    saveLayout(saveCopy, layoutName);

    this.onHide();
  }

  /**
   * Set wheather the layout should be saved or a copy should be
   * created
   *
   * @param saveCopy
   */
  setSaveCopy(saveCopy: boolean): void {
    this.setState({ saveCopy });
  }

  /**
   * Reset the modal to its original state
   */
  resetModal(): void {
    this.setState({
      layoutName: '',
      saveCopy: false,
    });
  }

  render(): JSX.Element {
    const { show } = this.props;
    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{MODAL_SAVE_LAYOUT_TITLE}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="save-name-modal-form" onSubmit={this.onSubmit} noValidate>
            <Row className="no-gutters">
              <Col className="p-0">
                <Form.Group>
                  <Form.Label>{MODAL_SAVE_LAYOUT_NAME_LABEL}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="new-password"
                    type="text"
                    placeholder={MODAL_SAVE_LAYOUT_NAME_PLACEHOLDER}
                    onChange={this.onChangeName}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {MODAL_SAVE_LAYOUT_NAME_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Row className="no-gutters w-100">
            <Col className="p-0">
              <button type="button" onClick={this.onClickAbort}>
                {BUTTON_ABORT}
              </button>
            </Col>
            <Col className="p-0">
              <button type="submit" form="save-name-modal-form">
                {BUTTON_SAVE}
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}
