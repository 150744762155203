import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Carousel, Col, Modal, Row } from 'react-bootstrap';

import {
  PreviewLayoutModalProps,
  PreviewLayoutModalState,
} from '../../@types/Modal.d';
import {
  BUTTON_CLOSE,
  BUTTON_PULISH,
  MODAL_PREVIEW_TITLE,
  MODAL_PREVIEW_TITLE_PUBLISH,
} from '../../constants/labels';

/**
 * A modal dialog to show a preview of the current layout or
 * to show the user the designed layout before approving it
 */
export default class PreviewLayoutModal extends React.Component<
  PreviewLayoutModalProps,
  PreviewLayoutModalState
> {
  nextIcon = (<FontAwesomeIcon icon={faChevronRight} />);

  prevIcon = (<FontAwesomeIcon icon={faChevronLeft} />);

  constructor(props: PreviewLayoutModalProps) {
    super(props);

    this.state = { publish: false, imageIndex: 0 };

    this.onClickClose = this.onClickClose.bind(this);
    this.onClickPublish = this.onClickPublish.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onSelect = this.onSelect.bind(this);

    this.resetModal = this.resetModal.bind(this);
    this.setPublish = this.setPublish.bind(this);
    this.setProofImages = this.setProofImages.bind(this);
  }

  /**
   * On hide method for the modal to hide the modal and reset it
   */
  onHide(): void {
    const { showModal } = this.props;

    showModal(false);
    this.resetModal();
  }

  /**
   * Onc click listener for the publish button
   *
   * @param event
   */
  onClickPublish(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    event.preventDefault();

    const { publishLayout } = this.props;

    publishLayout();

    this.onHide();
  }

  /**
   * On click listener for the close button
   *
   * @param event
   */
  onClickClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    event.preventDefault();

    this.onHide();
  }

  /**
   * Select a image via the page indicators
   *
   * @param eventKey
   */
  onSelect(eventKey: number): void {
    this.setState({ imageIndex: eventKey });
  }

  /**
   * Set the preview modal in publish mode
   * @param publish
   */
  setPublish(publish: boolean): void {
    this.setState({ publish });
  }

  /**
   * Set the images shown
   *
   * @param proofImages
   */
  setProofImages(proofImages?: string[]): void {
    this.setState({ proofImages });
  }

  /**
   * Revert the modal to its original state
   */
  resetModal(): void {
    this.setState({ proofImages: undefined, publish: false, imageIndex: 0 });
  }

  render(): JSX.Element {
    const { show } = this.props;
    const { publish, proofImages, imageIndex } = this.state;

    return (
      <Modal show={show} onHide={this.onHide} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {publish ? MODAL_PREVIEW_TITLE_PUBLISH : MODAL_PREVIEW_TITLE}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel
            className="image-carousel"
            activeIndex={imageIndex}
            onSelect={this.onSelect}
            interval={null}
            nextIcon={this.nextIcon}
            prevIcon={this.prevIcon}
            wrap={false}
          >
            {proofImages?.map((image, index) => (
              <Carousel.Item key={`${image}`}>
                <img src={image} alt={`proofimage-${index}`} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <Row className="no-gutters w-100">
            <Col xs={6} className="p-0">
              <button type="button" onClick={this.onClickClose}>
                {BUTTON_CLOSE}
              </button>
            </Col>
            {publish && imageIndex === (proofImages?.length ?? 0) - 1 && (
              <Col className="p-0">
                <button type="button" onClick={this.onClickPublish}>
                  {BUTTON_PULISH}
                </button>
              </Col>
            )}
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}
