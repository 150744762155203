import 'bootstrap/dist/css/bootstrap.min.css';

import '../styles/App.scss';
import '../styles/Common.scss';
import '../styles/Modal.scss';

import React from 'react';

import { Route, Switch, withRouter } from 'react-router-dom';

import TemplateSelection from './TemplateSelection';
import LayoutDesignContainer from './LayoutDesign/LayoutDesignContainer';
import LayoutSelection from './LayoutSelection';
import LoadingOverlay from './Common/LoadingOverlay';
import Home from './Home';

import {
  ROUTER_ROUTE_EDITOR_LAYOUT_ROUTE,
  ROUTER_ROUTE_EDITOR_TEMPLATE_ROUTE,
  ROUTER_ROUTE_HOME,
  ROUTER_ROUTE_LAYOUTS,
  ROUTER_ROUTE_TEMPLATES,
} from '../constants/constants';
import { getClient, getClientLocations } from '../util/api';

import { Client, ClientLocation } from '../@types/Data.d';
import {
  Message,
  MessageType,
  NavigationMessageType,
} from '../@types/MessageTypes.d';
import { AppProps, AppState } from '../@types/App.d';
import NotFound from './Common/NotFound';
import { REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS } from '../constants/network';
import { sendFinishAction } from '../util/sendMessage';

/**
 * Component which holds the application
 */
class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      showLoading: false,
      requestIdentifiers: [],
    };

    this.onReceiveMessage = this.onReceiveMessage.bind(this);

    this.fetchClient = this.fetchClient.bind(this);
    this.fetchClientLocations = this.fetchClientLocations.bind(this);

    this.showLoading = this.showLoading.bind(this);

    this.sendFinishAction = this.sendFinishAction.bind(this);
  }

  componentDidMount(): void {
    this.fetchClient();

    window.addEventListener('message', this.onReceiveMessage);

    // eslint-disable-next-line react/destructuring-assignment
    if (!this.props.history) return;

    const { history, initTarget } = this.props;

    if (!initTarget) return;

    history.push(initTarget);
  }

  componentDidUpdate(prevProps: AppProps): void {
    const { clientUUID } = this.props;

    if (clientUUID !== prevProps.clientUUID) this.fetchClient();
  }

  /**
   * Listen to messages from FPP
   *
   * @param event
   * @returns
   */
  onReceiveMessage(event: MessageEvent): void {
    let { data } = event;

    if (!(data as Message).type) return;

    data = data as Message;
    const { payload, type } = data;

    if (type === MessageType.NAVIGATE) {
      // eslint-disable-next-line react/destructuring-assignment
      if (!this.props.history) return;

      const { history } = this.props;

      if (!history) return;

      history.push(payload as NavigationMessageType);
    }
  }

  /**
   * Fetch the client
   *
   * @returns
   */
  async fetchClient(): Promise<void> {
    const { clientUUID } = this.props;

    if (!clientUUID) return;

    const client = (await getClient(clientUUID)) as Client;

    if (!client) return;

    this.setState(
      {
        client,
      },
      () => {
        // After the client is received fetch the clients locations
        this.fetchClientLocations();
      }
    );
  }

  /**
   * Fetch the clients locations from the server
   *
   * @returns
   */
  async fetchClientLocations(): Promise<void> {
    const { client } = this.state;

    if (!client) return;

    this.showLoading(true, REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS);

    const clientLocations = (await getClientLocations(
      client.uuid
    )) as ClientLocation[];

    if (!Number.isNaN(+clientLocations)) {
      this.showLoading(false, REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS);
      return;
    }

    client.clientLocations = clientLocations;

    this.setState(
      {
        client,
      },
      () => this.showLoading(false, REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS)
    );
  }

  /**
   * Enable the loading overlay
   *
   * @param loading
   * @param requestIdentifier
   */
  showLoading(loading: boolean, requestIdentifier: string): void {
    const { requestIdentifiers } = this.state;

    let requestIdentifiersNew = [] as string[];

    if (loading)
      // If loading is true add the request identifier to the array
      requestIdentifiersNew = [...requestIdentifiers, ...[requestIdentifier]];
    else {
      // If loading is false remove the request identifier from the array
      const index = requestIdentifiers.findIndex(
        (r) => r === requestIdentifier
      );

      if (index >= 0)
        requestIdentifiersNew = [
          ...requestIdentifiers.slice(0, index),
          ...requestIdentifiers.slice(index + 1),
        ];
    }

    this.setState({
      requestIdentifiers: requestIdentifiersNew,
      showLoading: requestIdentifiersNew.length > 0, // If the array is empty hide the loadinf overlay
    });
  }

  /**
   * Send the finish action to navigate to another section of FPP
   *
   * @returns
   */
  sendFinishAction(): void {
    const { parentOrigin } = this.props;

    if (!parentOrigin) return;

    sendFinishAction(parentOrigin);
  }

  render(): JSX.Element {
    const { client, showLoading } = this.state;

    return (
      <div id="app-container">
        {showLoading && (
          <LoadingOverlay
            loadingTitle="Bitte warten"
            loadingSubtitle="Ihre Anfrage wird bearbeitet"
          />
        )}
        <div className="app-content-container">
          <Switch>
            <Route path={ROUTER_ROUTE_TEMPLATES}>
              <TemplateSelection
                client={client}
                showLoading={this.showLoading}
              />
            </Route>
            <Route path={ROUTER_ROUTE_LAYOUTS}>
              <LayoutSelection client={client} showLoading={this.showLoading} />
            </Route>
            <Route path={ROUTER_ROUTE_EDITOR_TEMPLATE_ROUTE}>
              <LayoutDesignContainer
                client={client}
                showLoading={this.showLoading}
                type="TEMPLATE"
              />
            </Route>
            <Route path={ROUTER_ROUTE_EDITOR_LAYOUT_ROUTE}>
              <LayoutDesignContainer
                client={client}
                showLoading={this.showLoading}
                type="LAYOUT"
                sendFinishAction={this.sendFinishAction}
              />
            </Route>
            <Route exact path={ROUTER_ROUTE_HOME}>
              <Home />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
