import '../styles/TemplateSelection.scss';

import * as React from 'react';

import { getClientTemplates } from '../util/api';

import {
  TemplateSelectionProps,
  TemplateSelectionState,
} from '../@types/App.d';
import { PrintTemplate } from '../@types/Data.d';
import CardSelectionList from './Common/CardSelectionList';
import { ROUTER_ROUTE_EDITOR_TEMPLATE } from '../constants/constants';
import { REQUEST_IDENTIFIER_GET_PRINT_TEMPLATES } from '../constants/network';
import { strCmp } from '../util/util';
import { TEMPLATE_SELECTION_EMPTY_LIST } from '../constants/labels';

/**
 *  A list of print templates enabled to be used by the client.
 *  These are used to create new layouts based on a template
 */
export default class TemplateSelection extends React.Component<
  TemplateSelectionProps,
  TemplateSelectionState
> {
  constructor(props: TemplateSelectionProps) {
    super(props);

    this.state = {
      templates: [],
    };

    this.fetchPrintTemplates = this.fetchPrintTemplates.bind(this);
  }

  componentDidMount(): void {
    this.fetchPrintTemplates();
  }

  componentDidUpdate(prevProps: TemplateSelectionProps): void {
    const { client } = this.props;

    if (client?.id !== prevProps.client?.id) this.fetchPrintTemplates();
  }

  /**
   * Fetch the clients print tempaltes
   *
   * @returns
   */
  async fetchPrintTemplates(): Promise<void> {
    const { client, showLoading } = this.props;

    if (!client) return;

    showLoading(true, REQUEST_IDENTIFIER_GET_PRINT_TEMPLATES);

    const templates = (await getClientTemplates(
      client.uuid
    )) as PrintTemplate[];

    if (!Number.isNaN(+templates)) {
      showLoading(false, REQUEST_IDENTIFIER_GET_PRINT_TEMPLATES);
      return;
    }

    this.setState(
      {
        templates: templates.sort((a: PrintTemplate, b: PrintTemplate) =>
          strCmp(a.name, b.name)
        ),
      },
      () => {
        showLoading(false, REQUEST_IDENTIFIER_GET_PRINT_TEMPLATES);
      }
    );
  }

  render(): JSX.Element {
    const { templates } = this.state;

    return (
      <div className="template-selection-container">
        <CardSelectionList
          items={templates}
          linkTo={ROUTER_ROUTE_EDITOR_TEMPLATE}
          emptyString={TEMPLATE_SELECTION_EMPTY_LIST}
        />
      </div>
    );
  }
}
