import axios, { AxiosError } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { MessageType, Message } from '../@types/MessageTypes.d';
import {
  HEADER_SET_COOKIE,
  HEADER_COOKIE,
  CUSTOMER_CANVAS_TEMPLATE_PREVIEW,
  CUSTOMER_CANVAS_TEMPLATE_FILES,
  PATH_CLIENT_GET,
  PATH_CLIENT_LAYOUTS_GET,
  PATH_CLIENT_LAYOUT_DELETE,
  PATH_CLIENT_LAYOUT_GET,
  PATH_CLIENT_LAYOUT_POST,
  PATH_CLIENT_LAYOUT_PUT,
  PATH_CLIENT_PRINT_LAYOUTS,
  PATH_CLIENT_PRINT_LAYOUT,
  PATH_CLIENT_LOCATIONS_GET,
  PATH_REFRESH,
} from '../constants/network';

import config from '../config';

import {
  extractClientLayouts,
  extractClientLayout,
  extractClient,
  extractClientPrintTemplates,
  extractClientPrintTemplate,
  extractClientLocations,
} from './responseUtil';

import {
  Client,
  ClientLayout,
  ClientLocation,
  PrintTemplate,
  StateFileState,
} from '../@types/Data.d';

// The axios instance used for api calls
export const axiosClient = axios.create({
  baseURL: config.apiUrl,
  responseType: 'json',
  withCredentials: true,
});
axiosClient.defaults.headers.common['X-API-Key-Token'] =
  process.env.REACT_APP_FRA_API_KEY;

/**
 * Interceptor to automatically refresh auth tokens on 401 and 403
 */
createAuthRefreshInterceptor(
  axiosClient,
  async (failedRequest: AxiosError) => {
    return axios
      .get(`${config.apiUrl}${PATH_REFRESH}`, {
        headers: failedRequest.config.headers,
        withCredentials: true,
      })
      .then(
        (res) => {
          if (failedRequest.response) {
            failedRequest.response.config.headers[HEADER_SET_COOKIE] =
              res.headers[HEADER_SET_COOKIE];
            failedRequest.response.config.headers[HEADER_COOKIE] =
              res.headers[HEADER_SET_COOKIE];
          }

          return Promise.resolve();
        },
        (error: AxiosError) => {
          const message = {
            payload: {},
            type: MessageType.LOGOUT,
          } as Message;

          window.parent.postMessage(message, document.location.href);

          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject(error as AxiosError);
        }
      );
  },
  { statusCodes: [401, 403] }
);

/**
 * Print the error mesage to the console
 *
 * @param error
 * @returns
 */
export const printError = (error: any): string => {
  // eslint-disable-next-line no-console
  if (config.printToConsole) console.log(error);
  return (error.response || error.request).status;
};

/**
 * Fetch a client from the server
 *
 * @param clientUUID
 * @returns
 */
export const getClient = (clientUUID: string): Promise<string | Client> =>
  axiosClient
    .get(PATH_CLIENT_GET(clientUUID), {
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => extractClient(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

/**
 * Fetch a clients print templates
 *
 * @param clientUUID
 * @returns
 */
export const getClientTemplates = (
  clientUUID: string
): Promise<string | PrintTemplate[]> =>
  axiosClient
    .get(PATH_CLIENT_PRINT_LAYOUTS(clientUUID), {
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => extractClientPrintTemplates(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

/**
 * Fetch a specific print template
 *
 * @param clientUUID
 * @param printTemplateId
 * @returns
 */
export const getPrintTemplate = (
  clientUUID: string,
  printTemplateId: number
): Promise<string | PrintTemplate> =>
  axiosClient
    .get(PATH_CLIENT_PRINT_LAYOUT(clientUUID, printTemplateId), {
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => extractClientPrintTemplate(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

/**
 * Get all of a clients layouts
 *
 * @param clientUUID
 * @param state
 * @returns
 */
export const getClientLayouts = (
  clientUUID: string,
  state: StateFileState
): Promise<string | ClientLayout[]> =>
  axiosClient
    .get(PATH_CLIENT_LAYOUTS_GET(clientUUID), {
      params: { state },
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => extractClientLayouts(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

/**
 * Get a specific laoyut
 *
 * @param clientUUID
 * @param layoutId
 * @returns
 */
export const getClientLayout = (
  clientUUID: string,
  layoutId: number
): Promise<string | ClientLayout> =>
  axiosClient
    .get(PATH_CLIENT_LAYOUT_GET(clientUUID, layoutId), {
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => extractClientLayout(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

/**
 * Create a new client layout
 *
 * @param clientUUID
 * @param layout
 * @returns
 */
export const postClientLayout = (
  clientUUID: string,
  layout: any
): Promise<string | ClientLayout> =>
  axiosClient
    .post(PATH_CLIENT_LAYOUT_POST(clientUUID), layout, {
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => extractClientLayout(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

/**
 * Update a client laoyut
 *
 * @param clientUUID
 * @param layout
 * @returns
 */
export const putClientLayout = (
  clientUUID: string,
  layout: any
): Promise<string | ClientLayout> =>
  axiosClient
    .put(PATH_CLIENT_LAYOUT_PUT(clientUUID), layout, {
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => extractClientLayout(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

/**
 * Delete a client layout
 *
 * @param clientUUID
 * @param layoutId
 * @returns
 */
export const deleteClientLayout = (
  clientUUID: string,
  layoutId: number
): Promise<boolean> =>
  axiosClient
    .delete(PATH_CLIENT_LAYOUT_DELETE(clientUUID, layoutId), {
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      () => true,
      (error) => {
        printError(error);
        return false;
      }
    )
    .catch((error) => {
      printError(error);
      return false;
    });

/**
 * Download the proof images of a layout
 *
 * @param imageURLs
 * @returns
 */
export const downloadLayoutProofImages = (
  imageURLs: string[]
): Promise<Blob[]> =>
  axios
    .all(
      imageURLs.map((imageURL) =>
        axios.get(imageURL, { responseType: 'arraybuffer' })
      )
    )
    .then((responses) =>
      responses.map(
        (response) => new Blob([response.data], { type: 'image/jpeg' })
      )
    );

/**
 * Retrieve the preview/thumbnail urls for a template
 *
 * @param subfolder
 * @returns
 */
export const getTemplatePreviewURL = async (
  subfolder: string
): Promise<string> => {
  const fileListParams = { subfolder };

  const firstFile = await axios
    .get(CUSTOMER_CANVAS_TEMPLATE_FILES, {
      params: fileListParams,
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => response.data[0] as string,
      () => undefined
    );

  if (!firstFile) return CUSTOMER_CANVAS_TEMPLATE_PREVIEW(subfolder);

  return CUSTOMER_CANVAS_TEMPLATE_PREVIEW(`${subfolder}/${firstFile}`);
};

/**
 * Fetch all of a clients locations
 *
 * @param clientUUID
 * @returns
 */
export const getClientLocations = (
  clientUUID: string
): Promise<string | ClientLocation[]> =>
  axiosClient
    .get(PATH_CLIENT_LOCATIONS_GET(clientUUID), {
      validateStatus: (status: number) => status < 300,
    })
    .then(
      (response) => extractClientLocations(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));
