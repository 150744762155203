import {
  ContentItem,
  ImageItem,
} from '@aurigma/design-atoms-model/Product/Items';
import moment from 'moment';
import { ClientLocation, CustomerCanvasClientData } from '../@types/Data.d';

/**
 * Compate two strings, ascending or descending
 *
 * @param a
 * @param b
 * @param desc
 * @returns
 */
export const strCmp = (a: string, b: string, desc?: boolean): number => {
  if (a.toLowerCase() > b.toLowerCase()) return desc ? -1 : 1;
  if (a.toLowerCase() < b.toLowerCase()) return desc ? 1 : -1;
  return 0;
};

/**
 * Extract ELN values (emission and consumption) from a given display name
 *
 * @param displayName
 * @returns
 */
export const extractELNValue = (
  displayName: string
): { emission: string; consumption: string } => {
  const [, values] = displayName.split('%');

  // eslint-disable-next-line prefer-const
  let [consumption, emission] = values.split('_');

  emission = emission.substring(0, emission.lastIndexOf('.'));

  return { emission, consumption };
};

/**
 * Extract ELN values (emission and consumption) from an array of given display names
 *
 * @param displayNames
 * @returns
 */
export const extractELNValues = (
  displayNames: string[]
): { emissions: number[]; consumptions: number[] } => {
  let consumptions = [] as number[];
  let emissions = [] as number[];

  displayNames.forEach((dispalyName) => {
    const { emission, consumption } = extractELNValue(dispalyName);

    if (!Number.isNaN(+consumption))
      consumptions = [...consumptions, ...[+consumption]];

    if (!Number.isNaN(+emission)) emissions = [...emissions, ...[+emission]];
  });

  return { emissions, consumptions };
};

/**
 * Extract a file name from a customer canvas image element
 *
 * @param imageItem
 * @returns
 */
export const extractFileName = (
  imageItem: ContentItem | null | undefined
): string => {
  if (!imageItem || imageItem === null) return '';

  return (
    (imageItem as ImageItem).displayName ??
    imageItem.tags?.sourceData?.name ??
    ''
  );
};

/**
 * Convert relevant client location data to a
 * CustomerCanvas compatible format
 *
 * @param clientLocation
 * @returns
 */
export const extractCustomerCanvasUserInfo = (
  clientLocation: ClientLocation
): CustomerCanvasClientData => {
  const {
    addressName,
    postcode,
    city,
    street,
    housenumber,
    fax,
    web,
    phone,
    email,
  } = clientLocation;

  // eslint-disable-next-line camelcase
  const Monat_Jahr = moment().format('MM/YY');

  return {
    Firmenname: addressName,
    PLZ: postcode,
    Ort: city,
    Strasse: `${street} ${housenumber}`,
    Telefon: phone ?? '',
    Fax: fax ?? '',
    Website: web ?? '',
    EMail: email ?? '',
    Monat_Jahr,
  };
};
