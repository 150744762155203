import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { NotFoundProps } from '../../@types/Common.d';
import {
  NOT_FOUND_CONTENT,
  NOT_FOUND_GO_BACK,
  NOT_FOUND_TITLE,
} from '../../constants/labels';

import FPLogo from '../../resources/img/FPLogo.png';

/**
 * Page not found page
 *
 * @param props
 * @returns
 */
const NotFound: React.FC<NotFoundProps> = (props: NotFoundProps) => {
  const { history } = props;

  const onClickGoBack = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    history.goBack();
  };

  const onKeyDownGoBack = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    history.goBack();
  };

  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <div className="logo-container">
          <img src={FPLogo} alt="FP_Logo" />
        </div>
        <div className="title">{NOT_FOUND_TITLE}</div>
        <div className="content">{NOT_FOUND_CONTENT}</div>
        <div
          className="go-back-button"
          role="button"
          onClick={onClickGoBack}
          onKeyDown={onKeyDownGoBack}
          tabIndex={-1}
        >
          {NOT_FOUND_GO_BACK}
        </div>
      </div>
    </div>
  );
};

export default withRouter(NotFound);
