// A file containing all network related values

import config from '../config';

export const FRA_API_KEY =
  'fmf2LsjNm5uKpXpDsqJgcCDri5UKcrgEEi1DNRHJeIhPeEej3CzldrDHR0DEAJtd';

export const CUSTOMER_CANVAS_URL = config.ccUrl;
export const CUSTOMER_CANVAS_TEMPLATE_FILES = `${CUSTOMER_CANVAS_URL}/api/ProductTemplates/designs`;
export const CUSTOMER_CANVAS_TEMPLATE_PREVIEW = (templateId: string): string =>
  `${CUSTOMER_CANVAS_URL}/api/ProductTemplates/designs/${templateId}?width=300&height=300`;
export const CUSTOMER_CANVAS_STATE_FILE = (
  userId: number,
  stateId: string
): string => `${CUSTOMER_CANVAS_URL}/api/users/${userId}/states/${stateId}`;

export const PATH_REFRESH = 'token/refresh';
export const PATH_CLIENT_GET = (clientUUID: string): string =>
  `client/${clientUUID}`;
export const PATH_CLIENT_LOCATIONS_GET = (clientUUID: string): string =>
  `/client/${clientUUID}/locations`;

export const PATH_CLIENT_LAYOUTS_GET = (clientUUID: string): string =>
  `client/${clientUUID}/layouts`;
export const PATH_CLIENT_LAYOUT_GET = (
  clientUUID: string,
  layoutId: number
): string => `client/${clientUUID}/layout/${layoutId}`;
export const PATH_CLIENT_LAYOUT_POST = (clientUUID: string): string =>
  `client/${clientUUID}/layout`;
export const PATH_CLIENT_LAYOUT_PUT = (clientUUID: string): string =>
  `}client/${clientUUID}/layout`;
export const PATH_CLIENT_LAYOUT_DELETE = (
  clientUUID: string,
  layoutId: number
): string => `client/${clientUUID}/layout/${layoutId}`;

export const PATH_CLIENT_PRINT_LAYOUTS = (clientUUID: string): string => `
client/${clientUUID}/layoutTemplates
`;
export const PATH_CLIENT_PRINT_LAYOUT = (
  clientUUID: string,
  templateId: number
): string => `
client/${clientUUID}/layoutTemplate/${templateId}
`;

export const PARAM_CLIENT_ID = 'clientid';
export const PARAM_PARENTORIGIN = 'parentorigin';
export const PARAM_INIT_TARGET = 'inittarget';

export const HEADER_SET_COOKIE = 'set-cookie';
export const HEADER_COOKIE = 'cookie';

export const REQUEST_IDENTIFIER_GET_CLIENT = 'GET_CLIENT';
export const REQUEST_IDENTIFIER_GET_CLIENT_TEMPLATES = 'GET_CLIENT_TEMPLATES';
export const REQUEST_IDENTIFIER_GET_CLIENT_TEMPLATE = 'GET_CLIENT_TEMPLATE';
export const REQUEST_IDENTIFIER_GET_PRINT_TEMPLATES = 'GET_PRINT_TEMPLATES';
export const REQUEST_IDENTIFIER_GET_PRINT_TEMPLATE = 'GET_PRINT_TEMPLATE';
export const REQUEST_IDENTIFIER_GET_CLIENT_LAYOUTS = 'GET_CLIENT_LAYOUTS';
export const REQUEST_IDENTIFIER_GET_CLIENT_LAYOUT = 'GET_CLIENT_LAYOUT';
export const REQUEST_IDENTIFIER_POST_CLIENT_LAYOUT = 'POST_CLIENT_LAYOUTS';
export const REQUEST_IDENTIFIER_PUT_CLIENT_LAYOUTS = 'PUT_CLIENT_LAYOUTS';
export const REQUEST_IDENTIFIER_DELETE_CLIENT_LAYOUT = 'DELETE_CLIENT_LAYOUTS';
export const REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS = 'GET_CLIENT_LOCATIONS';

export const REQUEST_IDENTIFIER_LOAD_CUSTOMER_CANVAS = 'LOAD_CUSTOMER_CANVAS';
export const REQUEST_IDENTIFIER_SAVE_PRODUCT = 'SAVE_PRODUCT';
export const REQUEST_IDENTIFIER_GET_PREVIEW = 'GET_PREVIEW';
export const REQUEST_IDENTIFIER_FINISH_PRODUCT = 'FINISH_PRODUCT';
export const REQUEST_IDENTIFIER_DOWNLOAD_PREVIEW_IMAGES =
  'DOWNLOAD_PREVIEW_IMAGES';
