import * as React from 'react';

import moment from 'moment';

import { EditorBuilder } from '@aurigma/design-editor-iframe/EditorBuilder';
import { Editor } from '@aurigma/design-editor-iframe/Editor';
import { PlaceholderItem } from '@aurigma/design-atoms-model/Product/Items';
import {
  CUSTOMER_CANVAS_CONFIG,
  ELN_IMAGE_NAME,
  ELN_IMAGE_TYPE,
} from '../../constants/constants';
import {
  CUSTOMER_CANVAS_URL,
  REQUEST_IDENTIFIER_LOAD_CUSTOMER_CANVAS,
  REQUEST_IDENTIFIER_SAVE_PRODUCT,
  REQUEST_IDENTIFIER_GET_PREVIEW,
  REQUEST_IDENTIFIER_FINISH_PRODUCT,
} from '../../constants/network';
import {
  extractCustomerCanvasUserInfo,
  extractELNValues,
  extractFileName,
} from '../../util/util';

import {
  CusotmerCanvasProps,
  CusotmerCanvasState,
} from '../../@types/LayoutDesign.d';
import {
  ClientLayout,
  CustomerCanvasCarData,
  FinishResult,
  PrintTemplate,
} from '../../@types/Data.d';

/**
 * Container component to manage the customer canvas
 */
export default class CustomerCanvasContainer extends React.Component<
  CusotmerCanvasProps,
  CusotmerCanvasState
> {
  iFrameRef = React.createRef<HTMLIFrameElement>();

  constructor(props: CusotmerCanvasProps) {
    super(props);

    this.state = {};

    this.finishDesign = this.finishDesign.bind(this);
    this.getPreview = this.getPreview.bind(this);
    this.resetEditor = this.resetEditor.bind(this);
    this.save = this.save.bind(this);
    this.insertDataFromImages = this.insertDataFromImages.bind(this);
    this.insertClientData = this.insertClientData.bind(this);
  }

  componentDidMount(): void {
    this.buildEditor();
  }

  componentDidUpdate(prevProps: CusotmerCanvasProps): void {
    const { client, layout, type, clientLocation } = this.props;

    if (
      prevProps.client?.id !== client?.id ||
      (prevProps.layout?.id !== layout?.id && prevProps.type === type)
    )
      this.buildEditor();
    else if (prevProps.clientLocation?.id !== clientLocation)
      this.insertClientData();
  }

  /**
   * Requests a preview image urls of the current layout from
   * the CustomerCanvas server
   *
   * @returns
   */
  async getPreview(): Promise<void | string[]> {
    const { showLoading } = this.props;
    const { editor } = this.state;

    if (!editor) return;

    showLoading(true, REQUEST_IDENTIFIER_GET_PREVIEW);

    const proofImages = await editor.getProofImages({
      maxHeight: 640,
      maxWidth: 640,
    });

    if (!proofImages) {
      showLoading(false, REQUEST_IDENTIFIER_GET_PREVIEW);

      return;
    }

    const previewUrls =
      proofImages?.proofImageUrls
        ?.map((proofImage) => proofImage.map((url) => url))
        .flat() ?? [];

    if (!previewUrls) {
      showLoading(false, REQUEST_IDENTIFIER_GET_PREVIEW);

      return;
    }

    showLoading(false, REQUEST_IDENTIFIER_GET_PREVIEW);

    return previewUrls;
  }

  /**
   * Reset the reditor to its original state
   */
  resetEditor(): void {
    this.buildEditor();
  }

  /**
   * Save the current layout
   *
   * @returns
   */
  async save(): Promise<void | Editor.ISaveProductResult> {
    const { editor } = this.state;
    const { client, showLoading } = this.props;

    if (!editor || !client) return;

    showLoading(true, REQUEST_IDENTIFIER_SAVE_PRODUCT);

    // Save the layout via the iFrame API
    const saveResult = (await editor.saveProduct(
      `${moment().format('DD-MM-YYYY-HH_mm_SS')}-${client.id}`
    )) as Editor.ISaveProductResult;

    if (!saveResult) {
      showLoading(false, REQUEST_IDENTIFIER_SAVE_PRODUCT);

      return;
    }

    showLoading(false, REQUEST_IDENTIFIER_SAVE_PRODUCT);

    return saveResult;
  }

  /**
   * Finish the layout design
   */
  async finishDesign(): Promise<void | FinishResult> {
    const { editor } = this.state;
    const { client, layout, showLoading } = this.props;

    if (!editor || !client) return;

    showLoading(true, REQUEST_IDENTIFIER_FINISH_PRODUCT);

    // Finish the layout design via the iFrame API
    const finishResult = (await editor.finishProductDesign({
      proofMaxHeight: 640,
      proofMaxWidth: 640,
      stateId:
        (layout as ClientLayout).stateFileId ??
        `${moment().format('DD-MM-YYYY-HH_mm_SS')}-${client.id}`,
    })) as Editor.IFinishDesignResult;

    if (!finishResult) {
      showLoading(false, REQUEST_IDENTIFIER_FINISH_PRODUCT);

      return;
    }

    showLoading(false, REQUEST_IDENTIFIER_FINISH_PRODUCT);

    // Return data required to finish the result for FRA
    // including a print ready pdf file
    return {
      documentPath: finishResult.hiResOutputUrls[0],
      proofImages:
        finishResult.proofImageUrls
          ?.map((proofImage) => proofImage.map((url) => url))
          .flat() ?? [],
    } as FinishResult;
  }

  /**
   * Initialize the CustomerCanvas editor
   *
   * @returns
   */
  async buildEditor(): Promise<void> {
    const { current } = this.iFrameRef;
    const { client, layout, showLoading } = this.props;

    if (current === null || !layout || !client) return;

    showLoading(true, REQUEST_IDENTIFIER_LOAD_CUSTOMER_CANVAS);

    let editorProduct;

    // If the editor is initialized with an existing layout
    if ((layout as ClientLayout).stateFileId) {
      const { stateFileId } = layout as ClientLayout;

      editorProduct = stateFileId;
    } else if ((layout as PrintTemplate).product) {
      // If a new layout is created
      const { product } = layout as PrintTemplate;

      editorProduct = product;
    }

    if (!editorProduct) {
      showLoading(false, REQUEST_IDENTIFIER_LOAD_CUSTOMER_CANVAS);

      return;
    }

    // Request a new editor from the CustomerCanvas server
    const editor = (await EditorBuilder.for(CUSTOMER_CANVAS_URL)
      .build(current, editorProduct, CUSTOMER_CANVAS_CONFIG(client))
      .then(
        async (nEditor: Editor) => nEditor,
        () => {}
      )) as Editor;

    // On change listener to update the emission and consumption values
    // when an image item changes
    editor.subscribe('ItemChanged', (item) => {
      if (item.type === ELN_IMAGE_TYPE && item.name === ELN_IMAGE_NAME)
        this.insertDataFromImages();
    });

    this.setState(
      {
        editor,
      },
      async () => {
        // Insert the customer specific data into the current layout
        await this.insertClientData();
        // Insert consumtion and emission values
        await this.insertDataFromImages();
        showLoading(false, REQUEST_IDENTIFIER_LOAD_CUSTOMER_CANVAS);
      }
    );
  }

  /**
   * Insert user specific data into the layout (addresses, names, etc.)
   *
   * @returns
   */
  async insertClientData(): Promise<void> {
    const { clientLocation } = this.props;
    const { editor } = this.state;

    if (!clientLocation || !editor) return;

    await editor.loadUserInfo(extractCustomerCanvasUserInfo(clientLocation));
  }

  /**
   * Extract and calculate emission and consumption data from the inserted
   * images
   *
   * @returns
   */
  async insertDataFromImages(): Promise<void> {
    const { editor } = this.state;

    if (!editor) return;

    // Retrieve the currently loaded product
    const product = await editor.getProduct();

    if (!product) return;

    // Get the products data model
    const productModel = await product.getProductModel();

    if (!productModel) return;

    // Get all items and filter for relevant items
    const allItems = productModel.getAllItems();
    const elnImages = allItems.filter(
      (item) => item.type === ELN_IMAGE_TYPE && item.name === ELN_IMAGE_NAME
    ) as PlaceholderItem[];

    if (elnImages === undefined || elnImages?.length === 0) return;

    // Extract the display names which contain the relevant data
    const displayNames = elnImages
      .map(({ content }) => extractFileName(content))
      .filter((val) => val);

    if (displayNames.length === 0) return;

    // Extract emisison and consumption values from the image display names
    const { emissions, consumptions } = extractELNValues(displayNames);

    // Get min and max values
    const maxEmission = Math.max(...emissions);
    const minEmission = Math.min(...emissions);

    const maxConsumption = Math.max(...consumptions);
    const minConsumption = Math.min(...consumptions);

    // Insert the calculated data into the layout
    editor.loadUserInfo({
      Kraftstoffverbrauch: `${maxConsumption.toLocaleString()}-${minConsumption.toLocaleString()}`,
      CO2_Emmission: `${maxEmission.toLocaleString()}-${minEmission.toLocaleString()}`,
    } as CustomerCanvasCarData);
  }

  render(): JSX.Element {
    return (
      <div className="customer-canvas-container">
        <iframe
          ref={this.iFrameRef}
          id="customer-canvas"
          title="Customer Canvas"
          className="customer-canvas-iframe"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    );
  }
}
