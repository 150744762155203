import { Message, MessagePayload, MessageType } from '../@types/MessageTypes.d';

/**
 * Send a iframe message to FPP
 *
 * @param type
 * @param payload
 * @param parentOrigin
 */
export const sendMessage = (
  type: MessageType,
  payload: MessagePayload,
  parentOrigin: string
): void => {
  const pPayload = { type, payload } as Message;

  window.parent.postMessage(pPayload, parentOrigin);
};

/**
 * Generate a finish design message
 *
 * @param parentOrigin
 * @returns
 */
export const sendFinishAction = (parentOrigin: string): void =>
  sendMessage(MessageType.FINISH_DESIGN, undefined, parentOrigin);
